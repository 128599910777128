import React from 'react';
import { Box, Input, HStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const SelectAntiquity = props => {
  const [antiquity, setAntiquity] = useState(null);


  useEffect(() => {
    let { value } = props;
    if (!!(value?.max || value?.min)) {
     setAntiquity(value);
    }else{
      setAntiquity(null)
    }
  }, [props.value]);


  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setAntiquity(prevState => ({
      ...prevState,
      [name]: value,
    }));
    event.target.type = 'value'
    props.onChange(event);
  };

  return (
    <Box placeholder={props.placeholder}  borderColor="#E77800" borderRadius={3}>
    <HStack fontSize={14} borderColor="#E77800" spacing={props.spacing? 2: 0} align="center">        
          <Input
            _hover={{
              borderColor:'#E77800'
            }}
            borderWidth="1.5px"
              _focus={{
            borderColor:'#E77800',
            boxShadow: 'none'
          }}   paddingInlineStart={2}
          borderRadius={4}
          borderRightRadius={props.spacing? 3: 0}

          paddingInlineEnd={0} bg={'white'} color="black" placeholder={props.placeholderMin ||"Mínimo"}
                value={antiquity?.min || ''}
                name={props.minName}
                _placeholder={
                  {
                  color:"black",
                  fontSize:15,
                  wordWrap: 'break-word',
                }
                }
                onChange={handleChange}
            >
            </Input>
            <Input 
              _hover={{
                borderColor:'#E77800'
              }}
              borderRightWidth="1.5px"
              borderTopWidth="1.5px"
              borderBlockEndWidth="1.5px"
              borderRadius={3}
              borderLeftWidth={props.spacing? "1.5px": 0}
               _focus={{
            borderColor:'#E77800',
            boxShadow: 'none'
          }} paddingInlineStart={2} paddingInlineEnd={0} borderLeftRadius={props.spacing? 3: 0} bg={'white'} color="black"  placeholder={props.placeholderMax ||"Máximo"}
                value={antiquity?.max || ''}
                name={props.maxName}
                _placeholder={
                  { 
                     color:"black",
                  fontSize:15,
                  wordWrap: 'break-word',
                }
                }
                onChange={handleChange}>
             
            </Input>        
     
    </HStack>
  </Box>
  );
};

export default SelectAntiquity;
