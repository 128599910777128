'use client'

import { Box, Flex, Image,  Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import formatter from '@/utils/formatter';
import { useRouter } from 'next/navigation';

import Link from 'next/link';
const UndertakingCard = props => {
  
  const { push } = useRouter();



const handlePropertyClick=(e)=>{
e.preventDefault()
router.push({pathname:"emprendimientos/[id]", query: {id:props.project.id}})
} 


  return (

   

      <Box
   
       onClick={() => {
         push(`emprendimientos/${props.project.id}`);
       }}
    cursor={'pointer'}
        m="20px"
        position={'relative'}
        overflow={'hidden'}
        borderRadius={'4px'}

        w={{
          base: '220px',
          sm: '300px',
          md: '250px',
          lg: '310px',
          xl: '350px',
        }}
        height={'385px'}>
        <Image
          src={props.arrayPictures ? props.arrayPictures[0] : ''}
          alt={'imagen propiedad'}
          w={{
            base: '220px',
            sm: '300px',
            md: '250px',
            lg: '310px',
            xl: '350px',
          }}
          minW={'150px'}
          maxW={'350px'}
          height={'275px'}
          objectFit="cover"
        />
        <Box
         
          bg={'rgba(80, 80, 80, 0.7)'}
          zIndex={30}
          position="absolute"
          color={'white'}
          height={'40px'}
          w={'100%'}
          left={0}
         padding={'5px 20px'}
          top={'235px'}
          display={'flex'}
alignItems={'center'}
justifyContent={'space-between'}
          ><Box>
           <Text
            fontFamily="Heebo, sans-serif"
            fontWeight={300}
            marginTop={'2px'}
            fontSize={'11px'}
            >UNIDADES DE  </Text>
          
             <Text
            fontFamily="Heebo, sans-serif"
            fontWeight={300}
            fontSize={'13px'}
            >
              {`${formatter.roomsOptions(props.project.rooms)} AMB.`}
           {/* {props.project.minorPricesUSD?`DESDE USD ${props.project.minorPricesUSD}`: 'CONSULTAR'}*/}
          </Text>
          </Box>
          <Text
            fontFamily="Heebo, sans-serif"
            fontWeight={400}
            fontSize={'20px'}
           >
              {props.project.steps}
        
          </Text>
        </Box>

        <Box
          textAlign={'start'}
          bg={'#656664'}
          padding={'8px'}
          borderTop="3px solid white"
          height={'140px'}
          display={'flex'}
          flexDirection={'column'}>
          <Text
            fontFamily="Heebo, sans-serif"
            textShadow={'1px 1px 3px rgba(0, 0, 0, 0.6)'}
            lineHeight={1.1}
            fontSize={'13px'}
            p={'2px 0px 3px 5px'}
            color={'white'}>
           
              {props.project?.fakeaddress ?props.project?.fakeaddress:props.project?.address}
       
          </Text>
          <Text
                fontFamily="Heebo, sans-serif"
                paddingLeft={'5px'}
                fontSize={'11px'}
                fontWeight={300}
                textShadow={'1px 1px 3px rgba(0, 0, 0, 0.6)'}
                color={'white'}>
              <span>
              {props.project.fullLocation}
           
            </span>
             {/* <span>
               {props.project.location?.zone
                 ? props.project.location.zone 
                 : ''}
                  {props.project.location?.city ?  '   |   ' + props.project.location?.city 
             : ''
           }
               
               {props.project.location?.country
                   ?  '   |   '+ props.project.location?.country
                   : ''}
             </span>*/} 
              </Text>
             
       

            <Box
           
              display={'flex'}
       alignItems={'flex-end'}
              flexDirection={'column'}
              borderRadius={'5px'}
             padding={'2px 10px'}
      
             >

              <Text fontFamily="Heebo, sans-serif"
                color={'white'}
                fontWeight={400}
                fontSize={'17px'}>{props.project.minorPricesUSD?`DESDE U$S ${props.project.minorPricesUSD}`: 'CONSULTAR'}</Text>
              <Text
                fontFamily="Heebo, sans-serif"
                color={'white'}
                fontWeight={300}
                fontSize={'11px'}>
                {formatter.replaceWord(props.project.delivery)}
              </Text>
            
            
            </Box>
      </Box>
      </Box>

    
 
  );
};

export default UndertakingCard;
