import React, { useEffect, useState } from 'react';
import AsyncSelect from "react-select/async";
import { components } from "react-select";

const SelectRealEstateTypeProperties = (props) => {
  const options = [
    { label: 'Departamento', value: 'Departamento' },
    { label: 'Casa', value: 'Casa' },
    { label: 'Cochera', value: 'Cochera' },
    { label: 'PH', value: 'PH' },
  ];

  const [currentValue, setValue] = useState('');

  useEffect(() => {
    let { value } = props;
    let userValue;
    if (value && !currentValue) {
      userValue = options.filter(option => value.includes(option.value));
      if (userValue && value) setValue(userValue);
    }
    
    if(!value){
      setValue(null)
    }
  }, [props.value]);

  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <input className='mr-1' id={props.label} type="checkbox" checked={props.isSelected} />
      <label htmlFor={props.label}>{props.label}</label>
    </components.Option>
  );

  const onChange = (selected) => {
    setValue(selected);
    let arrayOfSelected ={ target:{
      name:'realEstateType' ,
      value: Array.from(selected, select=> select.value),
      type: 'value'
    }     
    }
    props.onChange(arrayOfSelected);
  };

  return (
    <AsyncSelect
      onChange={onChange}
      isMulti
      value={currentValue}
      closeMenuOnSelect={false}
      defaultOptions={options}
      hideSelectedOptions={false}
      loadOptions={options}
      isSearchable={false}
      placeholder={'Tipo'}
      components={{ Option: CheckboxOption }}
    />
  );
};

export default SelectRealEstateTypeProperties;