import React, { useEffect, useState } from 'react';
import { Box, Checkbox, HStack, Input } from '@chakra-ui/react';



function AreaForConstruction(props) {


  const [inputValues, setInputValue] = useState({
    min:'',
    max:'',  
  });

  useEffect(() => {
    let { value } = props;
    if (!!(value?.min || value?.max)) {
     
        setInputValue(value);

    }else{
      setInputValue({
        min:'',
        max:'',  
      })
    }
  }, [props.value]);







  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  
    setInputValue(prevState => ({
      ...prevState,
      [name]: value,
    }));
    event.target.type = 'value'
    props.onChange(event);
  };


  
  return (



    <Box placeholder={props.placeholder} color={'grey'} borderColor="#E77800" borderRadius={3} >
    <HStack fontSize={14} borderColor="#E77800" spacing={props.spacing? 2: 0} align="center">        
          <Input
            _hover={{
              borderColor:'#E77800'
            }}
            borderWidth="1.5px"
              _focus={{
            borderColor:'#E77800',
            boxShadow: 'none'
          }}   paddingInlineStart={3}
          borderRadius={4}
          borderRightRadius={props.spacing? 3: 0}

          paddingInlineEnd={0} bg={'white'} color="black"  placeholder={props.placeholderMin ||"Sup.Minima"}
          value={inputValues.min}
          name={props.minName}
               
                _placeholder={
                  {
                    color:'#808080',
                    fontSize:15,
                }
                }
                onChange={handleChange}
            >
            </Input>
            <Input borderColor="#E77800"
              _hover={{
                borderColor:'#E77800'
              }}
              borderRightWidth="1.5px"
              borderTopWidth="1.5px"
              borderBlockEndWidth="1.5px"
              borderRadius={4}
              borderLeftWidth={props.spacing? "1.5px": 0}
               _focus={{
            borderColor:'#E77800',
            boxShadow: 'none'
          }} paddingInlineStart={3} paddingInlineEnd={0} borderLeftRadius={props.spacing? 3: 0} bg={'white'} color="black"  placeholder={props.placeholderMax ||"Sup.Máxima"}
          value={inputValues.max}
                name={props.maxName}
                _placeholder={
                  {
                  fontSize:15,
color:'#808080'
                }
                }
                onChange={handleChange}>
             
            </Input>        
     
    </HStack>
  </Box>
   
  );
}

export default AreaForConstruction;