'use client';
import React, { useEffect, useState, createContext, useContext } from 'react';
import { Box, Center, Image, Spinner, Text,  } from '@chakra-ui/react';
import Carrousel from '@/components/Carrousel';

import { usePathname, useRouter } from 'next/navigation';
import ContactUs from './contactUs';
import IwinData from './iwinData';
import ToInvest from './toInvest';
import Footer from '@/components/Footer';
import NavBarIwin from '@/components/NavBarIwin';
import ChatBot from '@/components/ChatBot';
import SelectFilters from './selectFilters';
import FilterContext from '@/components/FilterContext';
import MostChosen from './mostChosen';
import apiHelpers from '@/utils/apiHelpers';
import OportunietiesCarousel from './OportunietiesCarousel';
import DevelopmentCarousel from './DevelopmentCarousel';

const Landing = props => {
  const [loading, setLoading] = useState(true);
  const [filtersContext, setFiltersContext] = useContext(FilterContext);
  const [devOportunities, setDevOportunities] = useState([]);
  const [oportunities, setOportunities] = useState([]);
  const [imagesForHome, setImagesForHome] = useState([]);
  const [porpertiesOnsale, setPropertiesOnSale] = useState(0);
  const [avilableLands, setAvilableLands] = useState(0);
  const [developmentsAvilable, setDevelopmentsAvilable] = useState(0);
  const [filters, setFilters] = useState({});
  const [isOpen,setIsOpen]=useState(false)


  const path = usePathname();
  const { push } = useRouter();

  const fetchData = async () => {
    setLoading(true);
    try {
     // const validSectionValues = ['carrousel', 'palermo', 'recoleta','nuñez','belgrano','las cañitas'];

 
      const [
        propertiesCountResponse,
        avilableLandsResponse,
        developmentsAvilableResponse,
        devOportunitiesResponse,
        oportunitiesResponse,
      ] = await Promise.all([
        apiHelpers.getInterwinPropertiesCount({ where: { operationType: ['venta'], origin: ['Tokko'] } }),
        apiHelpers.getInterwinPropertiesCount({ where: { realEstateType: ['Local','Oficina'], origin: ['Tokko'] } }),
        apiHelpers.getInterwinPropertiesCount({ where: { postingType: ['Emprendimiento'], origin: ['Tokko'] } }),
        apiHelpers.getInterwinProperties({ where: { postingType: ['Emprendimiento'], starredOnweb: '1', origin: ['Tokko'] }, take: 20 }),
        apiHelpers.getInterwinProperties({ where: { postingType: ['Departamento', 'Casa', 'PH'], starredOnweb: '1', postingType: ['Propiedad'],  operationType:['venta'], origin: ['Tokko'] }, take: 20 })
      ]);

      setPropertiesOnSale(propertiesCountResponse.data.count);
      setAvilableLands(avilableLandsResponse.data.count);
      setDevelopmentsAvilable(developmentsAvilableResponse.data.count);
      setDevOportunities(devOportunitiesResponse.data);
      setOportunities(oportunitiesResponse.data);

      const validSectionValues = ['carrousel', 'palermo', 'recoleta', 'nuñez', 'belgrano', 'las cañitas'];
      const images = [];
  
      // Iterar sobre las secciones válidas para obtener los archivos correspondientes
      for (const sectionValue of validSectionValues) {
        const filesInSectionResponse = await apiHelpers.getFiles({ where: { section: sectionValue } });
        const sectionImages = filesInSectionResponse.data.map(file => {
          file.url = apiHelpers.getFileURL(file.id);
          return file;
        });
        images.push(...sectionImages);
      }
  
      setImagesForHome(images);

     /* const images = filesResponse.data.map(file => {
        file.url = apiHelpers.getFileURL(file.id);
        return file;
      });
      setImagesForHome(images);*/
    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
  };


  useEffect(() => {
    fetchData();
  }, []);
   

  



  const breakpoints = {
    base: '20em',
    sm: '25em',
    md: '45em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  };



  const toInvest = [
    { name: 'Participá', subTitle: 'de un proyecto', image: 'participar.jpg', goTo: '/inversiones' },
    { name: 'En Pozo', image: 'pozo.jpg', goTo: 'En Pozo', type: 'Emprendimiento' },
    { name: 'En Obra', image: 'obra.jpeg', goTo: 'En Obra', type: 'Emprendimiento' },
    { name: 'Terminados', image: 'terminado.jpg', goTo: 'Terminado', type: 'Emprendimiento' },
    { name: 'Retasados', type: 'Propiedad', goTo: 'oportunidades', image: 'renta.jpg' },
  ];

  const images = ['slider1.jpg', 'slider3.jpg', 'slider4.jpg', 'slider5.jpg', 'slider6.jpg'];

  const handleChangeFilters = filters => {
    setFilters(filters);
  };
  const openMobileFilters=(isOpen)=>{
    setIsOpen(isOpen)
  }

  const goToProperties = () => {
    setLoading(true);
    push('/propiedades');
  };

  const goToFilteredProps = filters => {
    setFiltersContext(filters);
    push('/propiedades');
  };


  return (

    <Box>
         
      <NavBarIwin
        pos={'fixed'}
        img1={'/img/iwin dark.png'}
        img2="/img/iwin.png"
      />
      <Carrousel
        defaultImg={'/img/slider1.jpg'}
        images={imagesForHome}
        autoPlay={true}
        showButtons={false}
        breakpoints={breakpoints}></Carrousel>

      <Center w={'95%'} zIndex={3} display={'block'} m={'0 auto'}>
        <Box
          position={'relative'}
          w={'100%'}
          display={'flex'}
          justifyContent={'center'}>
          <SelectFilters
            loader={loading}
            loadData={handleChangeFilters}
            goToProps={goToProperties}
            isOpen={openMobileFilters}
            
          />
          {/* <PropertiesFilters
            count={count}
            uploadFilters={searchFilters}
            loadData={handleChangeFilters}
  goToProps={goToProperties}></PropertiesFilters>*/}
        </Box>
      </Center>
      <Box
        mt={{
          base: isOpen ? '670px' : '90px',
          sm: '430px',
          md: '350px',
          lg: '220px',
          xl: '100px',
        }}>
        <Box
          m="0px auto"
          width={'95%'}
          display={'flex'}
          flexDirection={'column'}>
          <Text
            fontSize={{ base: '20px', sm: '25px', md: '25px', lg: '35px' }}
            m={{ base: '10px', sm: '10px', md: '40px', lg: '55px', xl: '50px' }}
            color="gray.700"
            fontWeight="400"
            fontFamily="Heebo, sans-serif"
            textAlign="center"
            lineHeight="1"
            letterSpacing="2px">
            Emprendimientos Destacados
          </Text>

          {!devOportunities.length && loading ? (
            <Box
              mb={5}
              minH={'100px'}
              p={2}
              w={'100%'}
              mt={0}
              borderRadius="lg">
              <Center>
                <Spinner mt={3} size="xl" color='orange' />
              </Center>
            </Box>
          ) : (
            <DevelopmentCarousel
              id="carrouseldev"
              oportunities={devOportunities}
              loading={loading}
            />
          )}
        </Box>
      </Box>
      <Box
        mt={{
          base: '50px',
          sm: '60px',
          md: '70px',
          lg: '90px',
          xl: '90px',
        }}>
        <Box
          m="0px auto"
          width={'95%'}
          display={'flex'}
          flexDirection={'column'}>
          <Text
            fontSize={{ base: '20px', sm: '25px', md: '25px', lg: '35px' }}
            m={{ base: '10px', sm: '10px', md: '40px', lg: '55px', xl: '50px' }}
            color="gray.700"
            fontWeight="400"
            fontFamily="Heebo, sans-serif"
            textAlign="center"
            lineHeight="1"
            letterSpacing="2px">
            Propiedades Destacadas
          </Text>

          {!oportunities.length && loading ? (
            <Box
              mb={5}
              minH={'100px'}
              p={2}
              w={'100%'}
              mt={0}
              borderRadius="lg">
              <Center>
                <Spinner mt={3} size="xl" color='orange' />
              </Center>
            </Box>
          ) : (
            <OportunietiesCarousel
              id="carrousel"
              oportunities={oportunities}
              loading={loading}
            />
          )}
        </Box>
      </Box>
      <Box
        mt={'80px'}
        width={'90%'}
        margin={'0 auto'}
        //bgImage={'/img/bgpropsOP.jpg'}
        //width={'100%'}
        //height={'440px'}
        //bgSize={500}
        //zIndex={1}
        //flexDirection={'column'}
        //justifyContent={'center'}
        //objectFit={'auto'}
      >
        <MostChosen images={imagesForHome} loadSection={goToFilteredProps} />
      </Box>

      <Box
        mt={{ base: '30px', sm: '30px', md: '40px', lg: '55px', xl: '50px' }}
        mb={{ base: '20px', sm: '20px', md: '40px', lg: '55px', xl: '50px' }}>
        <ContactUs textAlign={'center'} />
      </Box>

      <Box
        marginTop={{ base: '80px' }}
        bg={'#999999'}
        height={{ base: '560px', sm: '260px' }}
        display={'flex'}
        alignItems={'center'}>
        <IwinData    porpertiesOnsale={porpertiesOnsale}  avilableLands={avilableLands}  developmentsAvilable={developmentsAvilable}/>
      </Box>
      <Box mt={20} width={'95%'} margin={'0 auto'}>
        <ToInvest
          toInvest={toInvest}
          loadSection={goToFilteredProps}
          ></ToInvest>
      </Box>

<ChatBot /> 

      <Footer justifyContent={'space-around'} />


    </Box>
 

  );
};
export default Landing; //withAuth(Properties);
