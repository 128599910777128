'use client';
import SelectCurrency from '@/components/selects/SelectCurrency';
import SelectLocation from '@/components/selects/SelectLocation';
import SelectInternationalLocation from '@/components/selects/SelectInternationalLocation';
import SelectRealEstateType from '@/components/selects/SelectRealEstateType';
import SelectRooms from '@/components/selects/SelectRooms';
import SelectTypeOfSale from '@/components/selects/SelectPublicationCategory';
import ExploitationSelect from '@/components/selects/ExploitationSelect';


import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Stack,
  Grid,
  Spacer,
  useBreakpointValue,

} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import SelectPrices from '@/components/selects/SelectPrices';
import { DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import AreaCheckbox from '@/components/selects/AreaCheckbox';
import SelectBasicFeatures from '@/components/selects/SelectBasicFeatures';
import SelectAntiquity from '@/components/selects/SelectAntiquity';
import FilterContext from '@/components/FilterContext';
import InitialFilter from './initialFilter';
import InitialFilterSelect from '@/components/selects/InitialFilterSelect';

import { usePathname, useRouter } from 'next/navigation';
import SelectRealEstateTypeProperties from '@/components/selects/SelectRealEstateTypeProperties';
import SelectSteps from '@/components/selects/SelectSteps';
import SelectRealEstateTypeComerce from '@/components/selects/SelectRealEstateTypeComerce';
import SelectAddress from '@/components/selects/SelectAddress';
import SelectZone from '@/components/selects/SelectZone';
import SelectIncidence from '@/components/selects/SelectIncidence';
import LotType from '@/components/selects/LotType';
import AreaForConstruction from '@/components/selects/AreaForConstruction';
import SelectInvestmentType from '@/components/selects/SelectInvestmentType';



const SelectFilters = props => {
  const { push } = useRouter();


  const initialState = {
    operationType: null,
    realEstateType: null,
    locationId: null,
    currency: null,
    rooms: null,
    max: null,
    min: null,
    coveredAreaMin: null,
    coveredAreaMax: null,
    totalAreaMin: null,
    totalAreaMax: null,
    locationName: null,
    antiquityMax: null,
    antiquityMin: null,
    garage: null,
    bathrooms: null,
    bedrooms: null,
    units: null,
    expensesMin: null,
    expensesMax: null,
    valueAreaMin: null,
    valueAreaMax: null,
    orientation: null,
    disposition: null,
    steps: null,
    delivery: null,
    floorsAmount: null,
    origin: null,
  };

  

  const initialStatusFilter={ postingType: ['Propiedad'],
    origin:['Tokko'],
    realEstateType:[],
  };
  const [state, setState] = useState(initialStatusFilter);
  const filterContext = useContext(FilterContext);
  const [filtersContext, setFiltersContext] = useContext(FilterContext);
  const [loading,setLoading]=useState(false);
  const [locations,setLocations]=useState([]);
  const [initialFilter, setInitialFilter] = useState('Viviendas');
  const [showFilters, setShowFilters] = useState(false);
  const [isMobile, setIsMobile] = useState(false);


  const  path= usePathname();
 





  useEffect(() => {
    setLoading(false);
  }, [path]);





  const goToProperties=(e)=>{
    e.preventDefault();
    setLoading(true);
  
    let newState = { ...state };
  
    if (initialFilter === 'Viviendas' && !state.realEstateType.length) {
      newState.realEstateType = ['Departamento', 'Casa', 'Cochera', 'PH'];
    } else if (initialFilter === 'Comercial' && !state.realEstateType.length) {
      newState.realEstateType = ['Local', 'Oficina', 'Edificio comercial', 'Depósito'];
    }else if(initialFilter==='Internacional' &&  !state.postingType.length){
      newState.postingType= ['Emprendimiento','Propiedad'];
    }
  

    setState(newState);
    setFiltersContext({ where: { ...newState } });

    push('/propiedades')
    //setLoading(false);
  }

  
  

  const onFiltersChange = event => {
    const target = event.target;
  
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setState(prevState => {
      return { ...prevState, [name]: value, origin: ['Tokko'] };
    });
  };




 /* const selectInitialFilter = filter => {
   setState({});
    setInitialFilter(filter);

    if (filter == 'Viviendas') {
      setState(prevState => ({
        ...prevState,
        postingType:'Propiedad',
      }));
    
    }

    if (filter == 'Emprendimientos') {
      setState(prevState => ({
        ...prevState,
        postingType:'Emprendimiento',
          }));
    }
    if (filter == 'Terrenos') {
      setState(prevState => ({
        ...prevState,
        realEstateType: ['Terreno']
      }));
    }
    if (filter == 'Campos') {
      setState(prevState => ({
        ...prevState,
        realEstateType: ['Campo']
      }));
    }

    if(filter=='Comercial'){
      setState(prevState => ({
        ...prevState,
        realEstateType: ['Comercial']
      }));
    }
 
  };
*/
const selectInitialFilter = filter => {
    
    setInitialFilter(filter);
  

  let newState = {};

  switch (filter) {

    case 'Viviendas':
      newState = { postingType: ['Propiedad'],
        origin:['Tokko'],
        realEstateType:[],
      };
      break;
    case 'Emprendimientos':
      newState = { postingType: ['Emprendimiento'], 
        origin:['Tokko']
      };
      break;
    case 'Terrenos':
      newState = { realEstateType: ['Terreno'],
        origin:['Tokko']
       };
      break;
    case 'Campos':
      newState = { realEstateType: ['Campo'],
        origin:['Tokko']
       };
      break;
    case 'Comercial':
      newState = { postingType: ['Propiedad'],
        origin:['Tokko'],
        realEstateType:[],
      };
      break;

      case 'Internacional':
        newState = { 
          origin:['Tokko'],
          isInternational:true,
          postingType: [], 

        };
        break;
    default:
newState={}
      break;
  }

  setState(newState);
};

  const selected = () => {
    switch (initialFilter) {
   

      case 'Viviendas':
        return (
          <Flex
            justifyContent={'center'}
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
              xl: 'row',
            }}
            alignItems={'center'}>
            <Grid
              w={'90%'} 
              templateColumns={{
                base: '1fr',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(4, 1fr)',
                xl: 'repeat(8, 1fr)',
              }} 
              gap={2}

              //templateColumns='repeat(4, 1fr)' gap={2}
            >
              <SelectTypeOfSale
                colSpan={{ base: 4, md:1}}
                name="operationType"
                value={state.operationType}
                onChange={onFiltersChange}></SelectTypeOfSale>
 <SelectRealEstateTypeProperties
                colSpan={{ base: 4, md: 1}}
                name="realEstateType"
                value={state.realEstateType}
                onChange={onFiltersChange}
                placeholder="Tipo"></SelectRealEstateTypeProperties>
                  
              <SelectLocation
                options={locations}
                colSpan={{ base: 4, md: 3 }}
                name="locationId"
                onChange={onFiltersChange}
                value={state.locationId}
                placeholder="Ubicación"/>
                 <SelectAddress colSpan={{ base: 4, md: 1}}
                name="address"
                onChange={onFiltersChange}
                value={state.address}/>
             
              <SelectRooms
                colSpan={{ base: 4, md: 3 }}
                name="rooms"
                onChange={onFiltersChange}
                value={state.rooms}
                placeholder="Ambientes"></SelectRooms>
              <SelectCurrency
                colSpan={{ base: 4, md: 3}}
                name="currency"
                onChange={onFiltersChange}
                value={state.currency}
                placeholder="Moneda"></SelectCurrency>
               <SelectPrices
         
         maxName="max"
         minName="min"
         value={{ min: state.min, max: state.max }}
         onChange={onFiltersChange}
         name="price"
  
         pad='1px' 
         placeholderMax="P. Máx."
         spacing={false}
         placeholderMin="P. Mín."></SelectPrices>
             
            

              <Button
                gridColumn={'span 1'}
                colSpan={{ base: 4, md: 1}}
                border={'#E77800!important'}
                onClick={goToProperties}
                isLoading={loading}
                key={'add'}
                height="40px"
                width="auto"
                bg="#E77800!important"
                _hover={{
                  bgColor: '#f8b46b !important',
                }}
                color={'white'}>
                <SearchIcon boxSize={6} borderWidth="none"></SearchIcon>
              </Button>
            </Grid>{' '}
          </Flex>
        );
     case 'Emprendimientos':
        return (
          <Flex justifyContent={'center'}>
            <Grid
              w={'90%'}
              templateColumns={{
                base: '1fr',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(3, 1fr)',
                xl: 'repeat(6, 1fr)',
              }}
              gap={2}
              alignItems={'center'}>
               
              <SelectLocation
                colSpan={{ base: 4, md: 1}}
                name="locationId"
                onChange={onFiltersChange}
                value={state.locationId}
                placeholder="Ubicación"></SelectLocation>
                 <SelectAddress colSpan={{ base: 4, md: 1}}
                name="address"
                placeholder="Direccion"
                onChange={onFiltersChange}
                value={state.address}/>
              <SelectRooms
               colSpan={{ base: 4, md: 1}}
                name="rooms"
                placeholder="Ambientes"
                onChange={onFiltersChange}
                value={state.rooms}></SelectRooms>
              <SelectPrices
                maxName="max"
                minName="min"
                value={{ min: state.min, max: state.max }}
                onChange={onFiltersChange}
                name="price"
                placeholderMax="Precio máx."
                spacing={false}
                placeholderMin="Precio mín."></SelectPrices>
              <SelectSteps
              colSpan={{ base: 4, md: 1}}
                name="steps"
                placeholder="Etapa de obra"
                onChange={onFiltersChange}
                value={state.steps}></SelectSteps>
      
              <Button
                border={'#E77800!important'}
                onClick={goToProperties}
                isLoading={loading}
                key={'add'}
                height="40px" 
                width="auto"
                bg="#E77800!important"
                _hover={{
                  bgColor: '#f8b46b !important',
                }}
                color={'white'}>
                <SearchIcon boxSize={6} borderWidth="none"></SearchIcon>
              </Button>
            </Grid>
          </Flex>
        );

      case 'Terrenos':
        return (
          <Flex justifyContent={'center'}>
            <Grid
              w={'90%'}
              templateColumns={{
                base: '1fr',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(5, 1fr)',
                xl: 'repeat(5, 1fr)',
              }}
              gap={2}
              alignItems={'center'}>
              {' '}
             
              <SelectLocation
    
                colSpan={{ base: 5, md: 1}}
                name="locationId"
                onChange={onFiltersChange}
                value={state.locationId}
                placeholder="Ubicación"></SelectLocation>
                 <SelectAddress colSpan={{ base: 5, md: 1}}
                name="address"
                placeholder="Direccion"
                onChange={onFiltersChange}
                value={state.address}/>
                   <AreaForConstruction
                   colSpan={{ base: 5, md: 1}}
                onChange={onFiltersChange}
                maxName="Desde"
                minName="Hasta"
                value={{
                  min: state.buildableArea,
                  max: state.buildableArea,
                }}></AreaForConstruction>

              { /* <SelectZone  name="zonification"
                placeholder="Zonificación"
                onChange={onFiltersChange}
                value={state.zonification} />
                <SelectIncidence name="incidence"
                placeholder="Incidencia"
                onChange={onFiltersChange}
                value={state.incidence}/>*/}
                <LotType name="characteristics"
                placeholder="Tipo de Lote"
                onChange={onFiltersChange}
                value={state.characteristics}/>
                       {  /* <SelectAddress colSpan={3}
                name="address"
                placeholder="Direccion"
                onChange={onFiltersChange}
                value={state.address}/>*/}
              <Button
                gridColumn={{
                  base: 'span 1',
                  sm: 'span 1',
                  md: 'span 1',
                  lg: 'span 1',
                  xl: 'span 1',
                }}
                border={'#E77800!important'}
                onClick={goToProperties}
                isLoading={loading}
                key={'add'}
                height="40px" 
                width="auto"
                bg="#E77800!important"
                _hover={{
                  bgColor: '#f8b46b !important',
                }}
                color={'white'}>
                <SearchIcon boxSize={6} borderWidth="none"></SearchIcon>
              </Button>
            </Grid>{' '}
          </Flex>
        );
      case 'Campos':
        return (
          <Flex justifyContent={'center'}>
            <Grid
              w={'90%'}
              templateColumns={{
                base: '1fr',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(3, 1fr)',
                xl: 'repeat(6, 1fr)',
              }}
              gap={2}
              alignItems={'center'}>
              {' '}
              <SelectTypeOfSale
                colSpan={{ base: 6, md: 3 }}
                name="operationType"
                value={state.operationType}
                onChange={onFiltersChange}></SelectTypeOfSale>
                  
              <SelectLocation
 
 colSpan={{ base: 6, md: 1}}
                name="locationId"
                onChange={onFiltersChange}
                value={state.locationId}
                placeholder="Ubicación"></SelectLocation>
                <SelectAddress colSpan={{ base: 6, md: 1}}
                name="address"
                placeholder="Direccion"
                onChange={onFiltersChange}
                value={state.address}/>
              <SelectPrices
                maxName="max"
                minName="min"
                value={{ min: state.min, max: state.max }}
                onChange={onFiltersChange}
                name="price"
                placeholderMax="P. máx."
                spacing={false}
                placeholderMin="P. mín."></SelectPrices>
                <ExploitationSelect
                name='extraAttributes'
                
                onChange={onFiltersChange}
                value={state.extraAttributes}/>
              <Button
                border={'#E77800!important'}
                onClick={goToProperties}
                isLoading={loading}
                key={'add'}
                height="40px" 
                width="auto"
                bg="#E77800!important"
                _hover={{
                  bgColor: '#f8b46b !important',
                }}
                color={'white'}>
                <SearchIcon boxSize={6} borderWidth="none"></SearchIcon>
              </Button>
            </Grid>{' '}
          </Flex>
        );
      case 'Internacional':
        return (
          <Flex justifyContent={'center'}>
            <Grid
              w={'90%'}
              templateColumns={{
                base: '1fr',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(5, 1fr)',
                xl: 'repeat(5, 1fr)',
              }}
              gap={2}
              alignItems={'center'}>
                   <SelectInternationalLocation

                colSpan={{ base: 5, md: 1}}
                name="locationId"
                onChange={onFiltersChange}
                value={state.locationId}
                placeholder="Ubicación"></SelectInternationalLocation>

<SelectAddress colSpan={{ base: 5, md: 1}}
                name="address"
                placeholder="Direccion"
                onChange={onFiltersChange}
                value={state.address}/>
              <SelectInvestmentType
               colSpan={{ base: 5, md: 1}}
                name="postingType"
                value={state.postingType}
                onChange={onFiltersChange}/>
           
           <SelectPrices
                maxName="max"
                minName="min"
                value={{ min: state.min, max: state.max }}
                onChange={onFiltersChange}
                name="price"
                placeholderMax="P. máx."
                spacing={false}
                placeholderMin="P. mín."></SelectPrices>
             
              <Button
                border={'#E77800!important'}
                onClick={goToProperties}
                isLoading={loading}
                key={'add'}
                height="40px" 
                width="auto"
                bg="#E77800!important"
                _hover={{
                  bgColor: '#f8b46b !important',
                }}
                color={'white'}>
                <SearchIcon boxSize={6} borderWidth="none"></SearchIcon>
              </Button>
            </Grid>{' '}
          </Flex>
        );
      case 'Comercial':
        return (
          <Flex justifyContent={'center'}>
            <Grid
              w={'90%'}
              templateColumns={{
                base: '1fr',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(3, 1fr)',
                xl: 'repeat(6, 1fr)',
              }}
              gap={2}
              alignItems={'center'}>
              <SelectTypeOfSale
               colSpan={{ base: 6, md: 1}}
                name="operationType"
                value={state.operationType}
                onChange={onFiltersChange}></SelectTypeOfSale>
              <SelectRealEstateTypeComerce
                colSpan={{ base: 6, md: 1}}
                name="realEstateType"
                value={state.realEstateType}
                onChange={onFiltersChange}
                placeholder="Tipo"></SelectRealEstateTypeComerce>
              <SelectLocation

                colSpan={3}
                name="locationId"
                onChange={onFiltersChange}
                value={state.locationId}
                placeholder="Ubicación"></SelectLocation>
              <SelectAddress colSpan={{ base: 6, md: 1}}
                name="address"
                placeholder="Direccion"
                onChange={onFiltersChange}
                value={state.address}/>
              <SelectPrices
                maxName="max"
                minName="min"
                value={{ min: state.min, max: state.max }}
                onChange={onFiltersChange}
                name="price"
                placeholderMax="Precio máx."
                spacing={false}
                placeholderMin="Precio mín."></SelectPrices>

{  /* <SelectAddress colSpan={3}
                name="address"
                placeholder="Direccion"
                onChange={onFiltersChange}
                value={state.address}/>*/}

              <Button
                gridColumn={'span 1'}
                border={'#E77800!important'}
                onClick={goToProperties}
                isLoading={loading}
                key={'add'}
                height="40px" 
                width="auto"
                bg="#E77800!important"
                _hover={{
                  bgColor: '#f8b46b !important',
                }}
                color={'white'}>
                <SearchIcon boxSize={6} borderWidth="none"></SearchIcon>
              </Button>
            </Grid>{' '}
          </Flex>
        );

      default:
        return 'Viviendas';
    }
  };

 

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 480); 
    }

    
    handleResize();
    window.addEventListener('resize', handleResize);

    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const toggleFilters = () => {
    setShowFilters(prevShowFilters => {
      const newShowFilters = !prevShowFilters;
      props.isOpen(newShowFilters); 
      return newShowFilters; 
    });
  };

  return (
    <>
      <Box
        position={'absolute'}
        width={'100%'}
        top={{ base: '-10', sm: '-35', md: '-20', lg: '-140px', xl: '-200px' }}
        zIndex={80}
        borderRadius="5px"
        bg="rgba(241, 243, 244)"
        boxShadow="4px 4px 6px rgba(128, 128, 128, 0.3)"
        mb={'20px'}
        pb={{ base: '20px', sm: '25px', md: '30px', lg: '40px', xl: '40px' }}
        pt={{ base: '20px', sm: '25px', md: '30px', lg: '40px', xl: '30px' }}>
      
 {isMobile ? (
  showFilters ? null:(
        <Flex justify={'center'} align='center' display={{ base: "flex", sm:"flex", md:"none" ,lg: "none", xl: "none","2xl":"none"}}>
        <Button onClick={toggleFilters} fontWeight='thin' bg='white !important' border='solid 1px #E77800' color='black' variant='outline' width={'90%'}>
          ¿Que éstas buscando?<Spacer/> <Box w='38px' h='28px' bg={'#E77800'} display='flex' justify={'center'} align='center'><SearchIcon m='0 auto' mt='3px' boxSize={5} color={'white'} 
                bg={'#E77800'}   ></SearchIcon></Box>
        </Button>
        </Flex> )
      ) : (
        <><Box
        mt={5}
        mb={5}
        display={{
          base: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex',
          xl: 'flex',
          '2xl': 'flex',
        }}
        justifyContent={'center'}>

        <InitialFilter selectedOption={selectInitialFilter} />
      </Box>

      <Center>
        <Stack key={initialFilter}>{selected()}</Stack>
    
      </Center></>
      )}

      {isMobile && showFilters && (
        <> 
        
        <Flex justify={'center'} align='center' m='0' display={{ base: "flex", sm:"flex", md:"none" ,lg: "none", xl: "none","2xl":"none"}}>
          <Button size='xs' borderRadiuw='5px' onClick={toggleFilters} fontWeight='thin' bg='#E77800 !important' color='white'  width={'90%'}>
          Mostrar menos
        </Button>
        </Flex>
        <Box
        mt={5}
        mb={5}
        display={{
          base: 'flex',
          sm: 'flex',
          md: 'flex',
          lg: 'flex',
          xl: 'flex',
          '2xl': 'flex',
        }}
        justifyContent={'center'}>

        <InitialFilter selectedOption={selectInitialFilter} />
      </Box>

      <Center>
        <Stack key={initialFilter}>{selected()}</Stack>
    
      </Center></>
      )}
  
      </Box>
      <Image
        zIndex={0}
        alt='no image'
        position="absolute"
        h={'auto'}
        w={'140px'}
        src="/img/decocirc.png"
        top={'-75px'}
        left={-5}></Image>
    </>
  );
};

export default SelectFilters;
