import { Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import AsyncSelect from "react-select/async";


const InitialFilterSelect = (props) => {
  const options = [

    { label: 'Viviendas', value: 'Viviendas' },
    { label: 'Emprendimientos', value: 'Emprendimientos' },
    { label: 'Propiedades Comerciales', value: 'Comercial' },
    { label: 'Terrenos', value: 'Terrenos' },
    { label: 'Campos', value: 'Campos' },
    { label: 'Internacional', value: 'Internacional' },
  ];

  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    // Initialize currentValue based on props.value
    if (props.value) {
      const selectedOption = options.find(option => option.value === props.value);
      setCurrentValue(selectedOption);
    } else {
      setCurrentValue(options[0]);
    }
  }, [props.value, options]);


  const onChange = (selected) => {
    setCurrentValue(selected);
    props.onChange(selected.value); 

  };


  return (
   
    <AsyncSelect
      onChange={onChange}
      value={currentValue}
      closeMenuOnSelect={true}
      defaultOptions={options}
      hideSelectedOptions={false}
      loadOptions={options}
      isSearchable={false}
      placeholder={'¿Qué estás buscando?'}

    />
  );
};

export default InitialFilterSelect;