import React, { useState,useEffect } from 'react';

import InitialFilterSelect from '@/components/selects/InitialFilterSelect';
import {
  Box,
  Button,
  Stack,
} from '@chakra-ui/react';

const InitialFilter = (props) => {

  const [selectedButton, setSelectedButton] = useState('Viviendas');

  const handleSelect = name => {
    setSelectedButton(name);
    props.selectedOption(name)
  };


  return (

    <Stack
      border="1px solid #E77800"
      backgroundColor={'white'}
      borderRadius={'8px'}
      spacing={2.2}
      padding={{
        base: '5px 10px',
        sm: '5px 10px',
        md: '5px',
        lg: '5px',
        xl: '5px',
      }}
     
      direction={
  'row'
      }
      width='92%'
      flexWrap="wrap"
      align={['center', 'flex-start']} // Alinea los elementos al centro en pantallas pequeñas y al inicio en pantallas grandes
      justifyContent={['space-evenly']}
      mb={'15px'}>
      <Button
        w={'180px'}
        borderRadius={'40px'}
        fontFamily="Heebo, sans-serif"
        fontWeight={400}
        fontSize={'1.1rem'}
        variant="nav"
        _hover={{
          bgColor: '#f8b46b !important',
          color: 'grey',
        }}
        color={selectedButton === 'Emprendimientos' ? 'white' : 'black'}
        bgColor={selectedButton === 'Emprendimientos' ? '#E77800 !important' : 'white'}
        onClick={() => handleSelect('Emprendimientos')}
        >
        Emprendimientos{' '}
      </Button>
      <Button
        w={'180px'}
        borderRadius={'40px'}
        fontFamily="Heebo, sans-serif"
        fontWeight={400}
        fontSize={'1.1rem'}
        variant="nav"
        _hover={{
          bgColor: '#f8b46b !important',
          color: 'grey',
        }}
        color={selectedButton === 'Viviendas' ? 'white' : 'black'}
        bgColor={selectedButton === 'Viviendas' ? '#E77800 !important' : 'white'}
        onClick={() => handleSelect('Viviendas')}>
        Viviendas{' '}
      </Button>
      <Button
        w={'215px'}
        borderRadius={'40px'}
        fontFamily="Heebo, sans-serif"
        fontWeight={400}
        fontSize={'1.1rem'}
        variant="nav"
        _hover={{
          bgColor: '#f8b46b !important',
          color: 'grey',
        }}
        color={selectedButton === 'Comercial' ? 'white' : 'black'}
        bgColor={selectedButton === 'Comercial' ? '#E77800 !important' : 'white'}
        onClick={() => handleSelect('Comercial')}>
       Propiedades comerciales{' '}
      </Button>
      <Button
        w={'180px'}
        borderRadius={'40px'}
        fontFamily="Heebo, sans-serif"
        fontWeight={400}
        fontSize={'1.1rem'}
        variant="nav"
        _hover={{
          bgColor: '#f8b46b !important',
          color: 'grey',
        }}
        color={selectedButton === 'Terrenos' ? 'white' : 'black'}
        bgColor={selectedButton === 'Terrenos' ? '#E77800 !important' : 'white'}
        onClick={() => handleSelect('Terrenos')}>
        Terrenos{' '}
      </Button>
      <Button
        w={'180px'}
        borderRadius={'40px'}
        fontFamily="Heebo, sans-serif"
        fontWeight={400}
        fontSize={'1.1rem'}
        variant="nav"
        _hover={{
          bgColor: '#f8b46b !important',
          color: 'grey',
        }}
        color={selectedButton === 'Campos' ? 'white' : 'black'}
        bgColor={selectedButton === 'Campos' ? '#E77800 !important' : 'white'}
        onClick={() => handleSelect('Campos')}>
        Campos{' '}
      </Button>
      <Button
        w={'180px'}
        borderRadius={'40px'}
        fontFamily="Heebo, sans-serif"
        fontWeight={400}
        fontSize={'1.1rem'}
        variant="nav"
        _hover={{
          bgColor: '#f8b46b !important',
          color: 'grey',
        }}
        color={selectedButton === 'Internacional' ? 'white' : 'black'}
        bgColor={selectedButton === 'Internacional' ? '#E77800 !important' : 'white'}
        onClick={() => handleSelect('Internacional')}>
        Internacional{' '}
      </Button>
    </Stack>
    
  );
};

export default InitialFilter;
