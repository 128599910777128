import React, { useEffect, useState } from 'react';
import AsyncSelect from "react-select/async";
import { components } from "react-select";

const ExploitationSelect = (props) => {
  const options = [
    { label: 'Agrícultura', value: 'Explotacion:Agricultura' },
    { label: 'Ganaderia', value: 'Explotacion:Ganaderia' },
    { label: 'Mixta', value: 'Explotacion:Mixta' },
 

  ];

  const [value, setValue] = useState('')

  useEffect(() => {
    let { value } = props;
    let userValue;
    if (value) {
      userValue = options.find(option => value.includes(option.value));
      if (userValue && value) setValue(userValue);
    }else{
      setValue(null)
    }
  }, [props.value]);

  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <input  className='mr-1' id={props.label} type="checkbox" checked={props.isSelected} />
      <label htmlFor={props.label}>{props.label}</label>
    </components.Option>
  );

  const onChange = (selected) => {
    setValue(selected);
    let arrayOfSelected ={ target:{
      name:'extraAttributes' ,
      value: Array.from(selected, select=> select.value),
      type: 'value'
    }     
    }
    props.onChange(arrayOfSelected);
  };

  return (
    <AsyncSelect
      onChange={onChange}
      value={value}
      isMulti
      closeMenuOnSelect={false}
      defaultOptions={options}
      hideSelectedOptions={false}
      loadOptions={options}
      isSearchable={false}
      placeholder={'Tipo de explotación'}
      components={{ Option: CheckboxOption }}
    />
  );
};


export default ExploitationSelect ;