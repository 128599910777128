import React, { useEffect, useState } from "react";

import { components, MultiValue, OptionProps } from "react-select";
import AsyncSelect from "react-select/async";

const SelectSteps = (props) => {
  const options = [
    { label: 'En Pozo', value: 'En Pozo' },
    { label: 'En Obra', value: 'En Obra'},
    { label: 'Terminada', value: 'Terminado'},

  ];

  const [currentValue, setValue] = useState('')

 

  useEffect(() => {
    let { value } = props;
    let userValue;
    if (value && !currentValue) {
      userValue = options.filter(option => value.includes(option.value));
      if (userValue && value) setValue(userValue);
    }
    if(!value){
      setValue(null)
    }
  }, [props.value]);





  const onChange = (selected) => {
    setValue(selected);
    
    let arrayOfSelected ={ target:{
      name:'steps' ,
      value: selected.value,
      type: 'value'
    }     
    }

    props.onChange(arrayOfSelected);
  };


  return (
    <AsyncSelect
    onChange={onChange}
      value={currentValue}
      closeMenuOnSelect={false}
      defaultOptions={options}
      hideSelectedOptions={false}
      isSearchable={false}
      placeholder={'Estado'}
    />
  );
};

export default SelectSteps;


