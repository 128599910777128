'use client';
import { Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'

const SelectZone = (props) => {


    const [zone, setZone] = useState(null);

    useEffect(() => {
        let { value } = props;
        let userValue;
        if (value && !zone) {
          userValue = options.filter(option => value.includes(option.value));
          if (userValue && value) setAddress(userValue);
        }
        if(!value){
          setZone(null)
        }
      }, [props.value]);
  

   


    const handleChange = event => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
    
      setAddress(
    value,
      );
      event.target.type = 'value'
      props.onChange(event);
    };






  return (
    <Input
paddingInlineStart={3}
borderColor="#E77800"
borderRadius={3}
bg={'white'}
color="black"
_focus={{
  borderColor: '#E77800',

  boxShadow: 'none',
}}
_placeholder={{
  color: '#808080',
  fontSize: 15,
}}
placeholder="Zonificacion"
name="address"
value={zone}
onChange={handleChange}></Input>
  )
}

export default SelectZone