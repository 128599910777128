import React, { useEffect, useState } from 'react';
import { Box, Input, HStack } from '@chakra-ui/react';

const SelectPrices = (props) => {
  const [price, setPrice] = useState(null);


  useEffect(() => {
    let { value } = props;
    if (!!(value[props.maxName] || value[props.minName])) {
      if(price){
        let lastState ={...price, ...value }
        setPrice(lastState)
      }else{
        setPrice(value);

      }
    }else{
      setPrice(null)
    }
  }, [props.value]);


  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  
    setPrice(prevState => ({
      ...prevState,
      [name]: value,
    }));
    event.target.type = 'value'
    props.onChange(event);
  };

  return (
    <Box placeholder={props.placeholder} color={'grey'} borderColor="#E77800" borderRadius={3}  >
      <HStack fontSize={14} borderColor="#E77800" spacing={props.spacing? 2: 0} align="center">        
            <Input
        
              _hover={{
                borderColor:'#E77800'
              }}
              borderWidth="1.5px"
                _focus={{
              borderColor:'#E77800',
              boxShadow: 'none'
            }}   paddingInlineStart={3}
            borderRadius={4}
            borderRightRadius={props.spacing? 3: 0}

            paddingInlineEnd={0} bg={'white'} color="black"  placeholder={props.placeholderMin ||"Mínimo"}
                  value={ price ? price[props.minName] : ''}
                  name={props.minName}
                  _placeholder={
                    {
                      color:'#808080',
                      fontSize: props.fSize || 15 ,
                  }
                  }
                  onChange={handleChange}
              >
              </Input>
              <Input borderColor="#E77800"
          
                _hover={{
                  borderColor:'#E77800'
                }}
                borderRightWidth="1.5px"
                borderTopWidth="1.5px"
                borderBlockEndWidth="1.5px"
                borderRadius={4}
                borderLeftWidth={props.spacing? "1.5px": 0}
                 _focus={{
              borderColor:'#E77800',
              boxShadow: 'none'
            }} paddingInlineStart={3} paddingInlineEnd={0} borderLeftRadius={props.spacing? 3: 0} bg={'white'} color="black"  placeholder={props.placeholderMax ||"Máximo"}
                  value={price ? price[props.maxName] : ''}
                  name={props.maxName}
                  _placeholder={
                    {
                      color:'#808080',
                      fontSize: props.fSize || 15 ,

                  }
                  }
                  onChange={handleChange}>
               
              </Input>        
       
      </HStack>
    </Box>
  );
};

export default SelectPrices;